.score-left{
    width: 55%;
}
.score-title{
    color: #424242;
    font-size: 18px;
    padding: 20px 0;
}
.score-left-content{
    color: #9B9B9B;
    font-size: 16px;
}
.score-right{
    width: 35%;
}
.score-subtitle{
    color: #424242;
    font-size: 18px;
    padding: 20px;
}
.score-img{
    width: 160px;
    height: 160px;
}
.score-bg-wrapper{
    position: relative;
    width: 160px;
    height: 160px;
    margin: 5px auto;
}
.score-container{
    position: absolute;
    left: 40%;
    top: 15%;
    color: #35A754;
    font-size: 30px;
}

.add-score-wrapper-block {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .time-wrapper {
    font-size:12px;
    font-family:NotoSansHans-Regular,NotoSansHans;
    font-weight:400;
    color:rgba(155,155,155,1);
    line-height:16px;
  }
   
  .add-score-explain {
    margin-top:14px;
    font-size:14px;
    font-family:NotoSansHans-Regular,NotoSansHans;
    font-weight:400;
    color:rgba(56,56,56,1);
    line-height:16px;
  }
  
  .right-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  
  .first-text {
    font-size:19px;
    font-family:NotoSansHans-Regular,NotoSansHans;
    font-weight:400;
    color:rgba(53,167,84,1);
    line-height:16px;
  }
  
  .second-text {
    margin-left: 4px;
    font-size:10px;
    font-family:NotoSansHans-Regular,NotoSansHans;
    font-weight:400;
    color:rgba(53,167,84,1);
    line-height:16px;
  }

  .text-wrapper {
    margin-top: 12px;
    width:100%;
    font-size:12px;
    font-family:NotoSansHans-Regular,NotoSansHans;
    font-weight:400;
    color:rgba(155,155,155,1);
    line-height:18px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
  }

  .text-line-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 55rpx;
    background-color: rgba(53, 167, 84, 1);
    font-size: 11px;
  }
  
  .text-line-1-left {
    width: 25%;
    color: white;
    border-right: solid 1px white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .text-line-1-middle {
    width: 50%;
    color: white;
    border-right: solid 1px white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .text-line-1-right {
    width: 25%;
    color: white;
    border-right: solid 1px white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .text-line-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 55rpx;
    font-size: 11px;
  }
  
  .text-line-wrapper-1 {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .text-line-wrapper-2 {
    background-color: rgba(153,204,153,0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .text-line-2-left {
    width: 25%;
    color: rgba(155,155,155,1);
    border-right: solid 1px white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .text-line-2-middle {
    width: 50%;
    color: rgba(155,155,155,1);
    border-right: solid 1px white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .text-line-2-right {
    width: 25%;
    color: rgba(155,155,155,1);
    border-right: solid 1px white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }