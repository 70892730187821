.signup-box{
    min-height: 600px;
}
.card-header{
    height: 50px;
}
.avatar{
    width: 50px;
    height: 50px;
    border-radius: 10px 0 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.avatar-img{
    width: 100%;
    height: 100%;
    border-radius: 10px 0 10px 10px;
}
.userName{
    font-size: 16px;
    padding-left: 20px;
    padding-right: 30px;
}
.score{
    color: #31A05F;
    font-size: 14px;
    padding: 0 5px;
}
.level{
    color: #989CA2;
    font-size: 14px;
    padding: 0 5px;
}
.card-desc-box{
    justify-content: flex-start;
    margin: 20px;
}
.card-desc {
    font-size: 12px;
    width: 50%;
    color:#989CA2;
    height:30px;
    padding: 10px 0;
    margin: 10px 0;
}
.card-title{
    color: #242424;
    font-size: 24px;
    padding-top: 20px;
}
.card-subtitle{
    color: #242424;
    font-size: 14px;
}
.card-radiobox{
    padding: 20px;
}
.radiobox-wrapper{

}
.selected{
    width: 20px;
    height: 20px;
    border: 1px solid #BABABA;
    color: #31A05F;
    text-align: center;
    border-radius: 10px;
}
.radiobox-name{
    padding: 0 10px;
}
.card-btn{
    width: 20%;
    height: 30px;
    border-radius: 15px;
    background-color: #31A05F;
    color: white;
    margin: 10px 40%;
}
.form-alert{
    color: red;
    font-size: 8px;
    padding: auto 5px;
}


.card-desc input{
    -webkit-appearance: none; -moz-appearance: none; -o-appearance: none; appearance: none;
    outline: none;
    background: none;
    border: none;
    border-bottom: 1px solid #989CA2;
    padding: 5px 0;
    color: #989CA2;
}
.card-desc input:focus{   
    border:none;
    border-bottom: 1px solid white;
}
.card-desc input::-webkit-input-placeholder{
    color:#D5E2FF;
}
input::-moz-placeholder{  
    color:#D5E2FF;
}
input:-ms-input-placeholder{  
    color: #D5E2FF;
}
