.act-wrapper{
    height: 130px;
    width: 100%;
    border-top: 1px solid #E1E1E1;
    padding: 15px 0;
}
.act-wrapper>a{
    width: 80%
}
.act-img{
    min-width: 150px;
    max-width: 150px;
    height: 100%;
    border: 1px solid #666;
}
.act-content{
    height: 100%;
    margin-left: 20px;
    flex-grow: 1;
}
.act-title{
    font-size: 14px;
    color: #424242;
    display: -webkit-box;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.4;
}
.act-desc{
    color: #A2A2A2;
    font-size: 12px;
    padding: 3px 0;
}
.act-status{
    min-width: 20%;
    height: 100%;
}
.act-cost{
    font-size:13px;
    color: #9B9B9B;
}
.act-signup{
    border-radius:12px;
    height:24px;
    line-height:24px;
    text-align:center;
    color:white;
    font-size:13px;
    background-color:#43AF6A;
}
.act-rank{
    min-width: 8%;
    line-height: 100px;
    text-align: center;
}
.act-rank:first-child{
    font-size: 19px
}
.act-rank:first-child + div{
    font-size: 20px
}
.act-green{
    color:#43AF6A;
}
.act-grey{
    color:#A2A2A2;
}