.part1-left {
    width: 42%;
}

.part1-left-main-pic {
    width: 380px;
    height: 280px;
    margin-bottom: 20px;
}

.part1-left-piclist {
    width: 50px;
    height: 0;
    padding-right: 10px;
}

.img-samll {
    width: 60px;
    height: 40px;
    padding: 10px 20px 20px 0;
}

.part1-right {
    width: 52%
}

.part1-right-title {
    font-size: 18px;
    color: #313131;
    padding: 15px 0;
}

.part1-right-cost {
    font-size: 22px;
    color: #2FA44A;
    padding: 10px 0;
}

.part1-right-number {
    font-size: 12px;
    color: #989CA2;
    padding: 15px 0;
}

.part1-right-detail {
    font-size: 14px;
}

.part1-right-detail>p {
    margin-bottom: 5px;
}

.part1-right-btn-wrapper>div {
    height: 30px;
    line-height: 30px;
    margin-right: 30px;
    text-align: center;
    font-size: 12px;
}

.part1-right-btn-share {
    background-color: #259E3A;
    color: white;
    width: 80px;
}

.part1-right-btn-like {
    color: #989CA2;
    border: 1px solid #DCDCDC;
    width: 80px;
}

.part1-btn-icon {
    width: 15px;
    height: 15px;
}

.part2 {
    margin: 60px 0;
}

.part2>div {
    width: 30%;
    background-color: white;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border: 1px solid #eee;
}

.part2>a {
    width: 30%;
    background-color: white;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border: 1px solid #eee;
}

.btn-type1 {
    color: #474747;
    cursor: pointer;
}

.btn-type2 {
    color: #A6AAB1;
    cursor: pointer;
}

.part3 {
    height: 40px;
    line-height: 40px;
}

.part3>div {
    width: 33%;
}

.par3-title {
    color: #242424;
    text-align: left;
}

.part3-number {
    color: #989CA2;
    text-align: center;
    font-size: 11px;
}

.part3-btn-wrapper {
    color: #43AF6A;
    font-size: 11px;
}

.part3-btn {
    border: 1px solid #43AF6A;
    border-radius: 15px;
    margin: 10px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    color: #43AF6A;
    font-size: 11px;
    width: 100px;
}

.part4 {
    background-color: white;
    margin-top: 40px;
}

.part4-title {
    color: #242424;
    padding: 20px 0;
    margin: 0 20px;
    border-bottom: 1px solid #EBEBEB;
}

.part4-content {
    color: #989CA2;
    font-size: 14px;
    padding: 20px;
}

.part3-btn-grey {
    background-color: #43AF6A;
    color: white;
}

.signup-btn {
    height: 30px;
    line-height: 30px;
    margin-right: 30px;
    text-align: center;
    font-size: 12px;
}

.signup-white {
    color: #989CA2;
    border: 1px solid #DCDCDC;
    width: 100px;
}

.signup-green {
    background-color: #259E3A;
    color: white;
    width: 80px;
}

.signup-reject {
    width: 120px;
}

.signup-cancel {
    height: 15px;
    line-height: 15px;
    display: block;
}

.w-normal {
    font-weight: normal;
}

.w-bold {
    font-weight: bold;
}

.i-italic {
    font-style: italic;
}

.i-normal {
    font-style: normal;
}

.f-10 {
    font-size: 10px;
}

.f-15 {
    font-size: 15px;
}

.f-20 {
    font-size: 20px;
}

.f-25 {
    font-size: 25px;
}

.align-left {
    text-align: left;
}

.align-middle {
    text-align: center;
}

.align-right {
    text-align: right;
}

.detail-img {
    width: 400px;
    margin: 10px 0;
}

/* ---------------------弹窗样式 -------------------*/

/* 遮挡层样式 */
.dialog-face {
    position: fixed;
    background: #364256;
    height: 100%;
    width: 100%;
    z-index: 1000;
    top: 0;
    left: 0;
    opacity: 0.7;
}

/* 弹窗布局样式 */
.dialog-root {
    position: fixed;
    z-index: 2000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow-y: scroll;
    background-color: white;
}

/* 弹窗容器 */
.dialog-wrapper {
    width: 80vw;
    height: 90vh;
    overflow-y: scroll;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.close-btn {
    position: absolute;
    color: #b6bdc5;
    right: 0;
    font-size: 30px;
    cursor: pointer;
    margin: 30px;
}

.pop-img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}