.welcome{
    height:400px;  
    /* border:1px solid #222;   */
    width: 100%;
    padding: 0 10%;
    box-sizing: border-box;
    background-image:url('../../../images/bg-welcome.png');
    background-size: 100% 100%;
  }  
  .welcome-content{
    max-width: 1000px;
  }
  .slider{  
    margin:20px;  
    max-width:640px;
    min-width:640px;
  }  
  .login{  
    width:35%;/*这里设置为占比1，填充满剩余空间*/  
    /* border:1px solid #ff4400;   */
    margin:20px;  
  } 
  .slider-full{
    width:100%;/*固定宽度*/  
    /* border:1px solid green;   */
    margin:20px;  
  }
  .qrcode{
    width:35%;
    margin:20px; 
    height: 260px;
  }
  .qrcode img{
    max-width:100%;
    height:260px;
  }