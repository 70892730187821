.header-wrapper {
    background-color: #31a551;
    height: 100px;
  }
  .header { 
    color: white;
  }
  .header-ul { 
    margin:10px;
  }
  .header-logo{
    width: 260px;
    height: 80px;
    margin-top:-20px;
  }
  .header li {
    list-style-type: none;
    width:88px;
    text-align: center;
    padding:10px 0;
  }
  .header li a{
    text-decoration: none;
    color: white;
  }
  .header li:hover a{
    font-weight: bold;
  }
  .hearder-location{
    padding: 0  20px;
    position: relative;
  }

  .loc-hover{
    position: absolute;
    border-radius: 5px;
    box-shadow: #a1a0a0 1px 1px 5px 2px;
    background-color: white;
    height: 210px;
    width: 250px;
    left: -180px;
    z-index: 100;
  }
  .loc-hover div{
    text-align: center;
    font-size: 10px;
    padding: 5px 10px;
  }
  .loc-hover div{
    color:#666666;
  }
  .loc-hover div:hover{
    color: #43AF6A;
  }
  .nav-style{
    flex: 0 0 auto;
  }
  .route-style{
    flex: 1 1 auto;
  }
  .container-style{
    display: flex;
    flex-direction: column;
    height: 100%;
  }