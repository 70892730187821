.subNavBar{
    width: 100%;
}
.sub-title{
    font-size: 20px;
    margin: 10px auto;
    color: #222A41;
}
.sub-navbar-wrapper{
    display: flex;
    flex-direction: row;
    margin: 10px auto;
}
.sub-navbar:hover{
    cursor: "pointer"
}
.sub-navbar{
    font-size: 15px;
    color: #57667E;
    padding: 0 18px;
    cursor: "pointer"
}
.sub-navbar-select{
    color: #222A41;
}