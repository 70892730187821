.card-header{
    height: 50px;
}
.avatar{
    width: 50px;
    height: 50px;
    border-radius: 10px 0 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.avatar-img{
    width: 100%;
    height: 100%;
    border-radius: 10px 0 10px 10px;
}
.userName{
    font-size: 16px;
    padding-left: 20px;
    padding-right: 30px;
}
.score{
    color: #31A05F;
    font-size: 14px;
    padding: 0 5px;
}
.level{
    color: #989CA2;
    font-size: 14px;
    padding: 0 5px;
}
.card-desc-box-1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px;
}
.card-desc-userCard {
    font-size: 12px;
    width: 49.5%;
    color:rgba(152,156,162,1);;
    height:60px;
    /* padding: 10px 0; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.card-title{
    color: #242424;
    size: 24px;
    padding-top: 20px;
}
.card-subtitle{
    color: #242424;
    size: 14px;
}
.card-checkbox{
    padding: 20px;
}
.checkbox-wrapper{

}
.selected{
    width: 20px;
    height: 20px;
    border: 1px solid #BABABA;
    color: #31A05F;
    text-align: center;
}
.checkbox-name{
    padding: 0 10px;
}
.card-btn{
    width: 20%;
    height: 30px;
    border-radius: 15px;
    background-color: #31A05F;
    color: white;
    margin: 10px 40%;
}

/*
.card-desc input{
    -webkit-appearance: none; -moz-appearance: none; -o-appearance: none; appearance: none;
    outline: none;
    background: none;
    border: none;
    border-bottom: 1px solid #989CA2;
    padding: 5px 0;
    color: #989CA2;
}
.card-desc input:focus{   
    border:none;
    border-bottom: 1px solid white;
}
.card-desc input::-webkit-input-placeholder{
    color:#D5E2FF;
}
input::-moz-placeholder{  
    color:#D5E2FF;
}
input:-ms-input-placeholder{  
    color: #D5E2FF;
}
*/