.slider-ads{
    height: 260px;
    width: 100%;
}
.slider-box{
    display: flex;
    flex-direction: row;
}
.slider-left{
    height: 260px;
    padding: 10px;
    box-sizing: border-box;
    min-width: 640px;
    max-width: 640px;
}
.slider-right{
    width: 50%;
    padding: 10px;
    box-sizing: border-box;
    color: white;
}