.activity-item-wrapper{
  width: 100%;
}  
.activity-item{
  height:300px;  
  width: 100%;
  margin: 10px 0;
}  
.imgbox-wapper{  
  width:42%;
  margin:20px;  
  height: 230px;
  max-width: 377px;
  min-width: 377px;
}  
.desbox-wrapper{  
  width:55%;
  margin:20px;  
} 
.desc-title{
  font-size: 18px;
  color:#233D63;
  margin:20px 0;  
  display: -webkit-box;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4;
}
.desc-content{
  font-size: 16px;
  color: #333333;
  margin-bottom:20px;  
}