.register-box {
    width: 300px;
    margin: 30px auto;
}

.input-text {
    position: relative;
}

.input-text>input {
    width: 100%;
    margin: 10px 0;
}

.login-btn {
    width: 100%;
    font-size: 16px;
    text-align: center;
}

.form-group input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: none;
    background: none;
    border: 1px solid rgb(192, 190, 190);
    padding: 5px 0;
    color: #989CA2;
    border-radius: 15px;
    padding-left: 15px;
}

.form-group input:focus {
    border: 1px solid rgb(192, 190, 190);
}

.login-btn {
    background-color: #239D38;
    height: 30px;
    border-radius: 15px;
    border: 1px solid rgb(168, 166, 166);
    color: white;
}

.verift-btn {
    position: absolute;
    right: 0;
    width: 80px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: white;
    top: 12px;
    border-radius: 15px;
    background-color: #239D38;
}

.form-alert {
    color: red;
    font-size: 8px;
    padding: auto 5px;
}

.form-group input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #989CA2;
}

.form-group input::-moz-placeholder {
    /* Firefox 19+ */
    color: #989CA2;
}

.form-group input:-ms-input-placeholder {
    /* IE 10+ */
    color: #989CA2;
}

.form-group input:-moz-placeholder {
    /* Firefox 18- */
    color: #989CA2;
}

.form-group.interest-tag {
    margin-bottom: 20px;
    margin-top: 20px;
}

.form-group .tag-title {
    font-size: 16px;
    font-weight: bold;
}

.form-group .tag-cell {
    display: inline-table;
    padding: 10px;
}