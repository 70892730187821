.signup-banner{
    height: 20px;
}
.signup-title{
    font-size: 12px;
    color: #9B9B9B;
    margin: 5px 0;
    padding: 0 15px;
    line-height: 10px;
    text-align: center;
    border-right: 1px solid #9B9B9B;
} 
.signup-title-first{
    border-left: 1px solid #9B9B9B;
}
.signup-title-select{
    color: #1F1F1F;
}