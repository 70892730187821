.flex-row {
    display: flex;
    flex-direction: row;
}
.flex-column {
    display: flex;
    flex-direction: column;
}
.flex-center{
    justify-content: center;
    align-items: center;
}
.flex-x-center{
    justify-content: center;
}
.flex-y-center{
    align-items: center;
}
.flex-wrap{
    flex-wrap: wrap;
}
.flex-nowrap{
    flex-wrap: nowrap;
}
.flex-twoside{
    justify-content: space-between;
}
.flex-around{
    justify-content: space-around;
}
.page-padding{
    margin: 20px auto;
    box-sizing: border-box;
    max-width: 1000px;
}
.full-width{
    width: 100%;
}

.link-style-clear{
    text-decoration:none
}
.activity-item-2-wrapper{
    min-height: 450px;
    width: 100%;
}
.flex-y-start{
    align-content:flex-start;
}
.page-min-height{
    
}
#root{
    height: 100%;
    background-color: #eeeeee;
}
.home-maxwidth{
    max-width: 900px;
    min-width: 900px;
}