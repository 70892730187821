.find-box{
    width: 33%;
    /*height: 32.7%;*/
    padding: 10px;
    box-sizing: border-box;
    height: 320px;
    position: relative;
}
.find-box-short{
    height: 22%;
}
.find-box-high{
    /*height: 38%*/
    height: 400px;
}

.find-img{
    width: 100%;
    height: 60%;
}
.find-img-full{
    height: 100%;
}
.find-content{
    position: relative;
    padding: 10px;
    box-sizing: border-box;
    min-height: 137px;
}
.find-title{
    color: #3E3E3E;
    font-size: 14px;
    margin-bottom: 5px;
    display: -webkit-box;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.4;
}
.find-desc{
    color: #A2A2A2;
    font-size: 12px;
    padding-bottom: 5px;
}
.find-signup{
    position:absolute;
    right:10px;
    bottom:10px;
    border-radius:12px;
    width:56px;
    height:24px;
    line-height:24px;
    text-align:center;
    color:white;
    font-size:13px;
    background-color:#43AF6A;
}
.find-box-high img{
    height: 68%;
}
.add-margin{
    margin-top: -100px;
}
.act-price{
    position: absolute;
    right: 10%;
    top: 50%;
    color: white;
}