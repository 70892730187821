.login-wrapper{
    padding: 10px 10%;
    box-sizing: border-box;
}
.title{
    width: 100%;
    height: 30px;
    line-height: 30px;
    margin: 10px auto;
    color: #fff;
    text-align: justify;
    font-size: 28px;
    font-weight: bold;
}

.title:after{
    display:inline-block;
    content:'';
    overflow:hidden;
    width:100%;
    height:0;
}

.login-box{
    width: 100%;
}
​
.input-text{
    margin:0px auto;
}
.input-text>input{
    width: 100%;
    margin: 0px 0;
}
.form-group input{
    -webkit-appearance: none; -moz-appearance: none; -o-appearance: none; appearance: none;
    outline: none;
    background: none;
    border: none;
    border-bottom: 1px solid white;
    padding: 5px 0;
    color: white;
}
.form-group input:focus{   
    border:none;
    border-bottom: 1px solid white;
}
.form-group input::-webkit-input-placeholder{
    color:#D5E2FF;
}
input::-moz-placeholder{   /* Mozilla Firefox 19+ */
    color:#D5E2FF;
}
input:-ms-input-placeholder{  /* Internet Explorer 10-11 */
    color: #D5E2FF;
}
.login-button{
    background: transparent;
    height: 30px;
    border-radius: 15px;
    border: 1px solid rgb(192, 190, 190);
    color: white;
    width: 100%;
    font-size: 16px;
    text-align: center;
}
.register{
    font-size: 10px;
    color: white;
    text-align: right;
    padding-top: 5px;
}

.register:hover{
    font-weight: bold; 
}