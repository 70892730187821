/**
*type 1: used in navbar
*/
.search-box-1 {
    position: relative;
    max-width: 100px;
    flex-grow:1;
}
.search-box-1 form {
    height: 42px;
}
.search-box-1 input {
    width: 100%;
    height: 30px;
    border-radius: 42px;
    background: #83AF6A;
    transition: .3s linear;
    float: right;
    outline: none;
    border: none;
}
.bar input:focus {
    width: 300px;
}
.search-box-1 button {
    background: none;
    top: -2px;
    right: 0;
}
.search-box-1 button:before{
  content: "\f002";
  font-family: FontAwesome;
  color: #324b4e;
}
.search-box-1 img{
    width: 15px; 
    position: absolute;
    right: 5px; 
    top: 7px;
} 

/**
*type 2: used in search page
*/
.search-box-2 {
    position: relative;
    width: 50%;
    margin: 20px auto;
    padding-bottom: 50px;
}
.search-box-2 form {
    height: 42px;
}
.search-box-2 input {
    width: 100%;
    height: 30px;
    border-radius: 42px;
    transition: .3s linear;
    float: right;
    outline: none;
    border: 2px solid #43AF6A;
    padding: 0 20px;
}
.bar input:focus {
    width: 300px;
}
.search-box-2 button {
    background: none;
    top: -2px;
    right: 0;
}
.search-box-2 button:before{
  content: "\f002";
  font-family: FontAwesome;
  color: #324b4e;
}
.search-box-2 img{
    width: 25px; 
    position: absolute;
    right: 10px; 
    top: 2px;
} 