.usr-img{
    padding: 20px 0;
    position: relative;
  }
  .user-hover{
    position: absolute;
    border-radius: 5px;
    box-shadow: #a1a0a0 1px 1px 5px 2px;
    background-color: white;
    height: 50px;
    line-height: 25px;
    width: 150px;
    left: -100px;
    z-index: 100;
  }
  .user-hover div{
    text-align: center;
    font-size: 10px;
  }
  .user-name{
    color: #666666;
  }
  .user-quit{
    color: #43AF6A;
  }
  .user-name:hover{
    color: #43AF6A;
  }
  