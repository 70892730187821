.footer-wrapper{
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: white;
    background-color: #31a551;
    margin-top: 40px;
    height: 50px;
    flex: 0 0 auto;
}
.footer-wrapper a{
    color: #fff;
    text-decoration: none;

}