.part1{
    height:300px;
    margin-top: 30px;
}
.part1-left{
    width: 42%;
}
.part1-left img {
    width: 100%;
    height: 100%;
}
.part1-left-main-pic{
    width: 380px;
    height: 280px;
    margin-bottom: 20px;
}
.part1-right{
    width: 52%
}
.part1-right-title{
    font-size: 18px;
    color: #313131;
    padding: 15px 0;
}
.block-wrapper{

}
.act-title{
    font-size: 16px;
}
.content div{
    color:#989CA2;
    font-size: 14px;
    margin: 5px 0;
}
.act-title-margin{
    margin: 20px 0;
}
.signup-list{
    line-height: 20px;
}
.signup-left{
    flex-grow: 1;
}
.signup-left div{
    color:#989CA2;
    font-size: 12px;
}
.signup-right{
    width: 100px;
    flex-grow: 0;
    background-color: #43AF6A;
    border-radius: 10px;
    line-height: 20px;
    text-align: center;
    color: white;
    font-size: 12px;
}
.signup-item{
    margin: 10px 0;
}
.signup-grey{
    background-color: #989CA2;
}
.part1-right-number{
    font-size: 12px;
    color: #989CA2;
    padding: 15px 0;
}