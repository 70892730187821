select{
    border: 0px;
    background-color: transparent;
    min-width: 80px;
    text-align: center;
    text-align-last: center
}
select:focus{
    border: none;
    box-shadow: none;
    outline: none;
}