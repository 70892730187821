.theme-wrapper{
    width: 100%;
    box-sizing: border-box;
}
.select-wrapper{
    border-bottom: 1px solid #E1E1E1;
    margin: 0 15%;
    padding: 10px 0;
}
.select{
    margin: 0 10px;
    width: 150px;
}
.select:nth-child(1){
    background: transparent;
}
.find{
    height: 900px;
    width: 100%;
}
.select-border{
    border-right: 1px solid #333;
}
