.change-btn{
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 12px;
    color:#233D63;
}

.list-wrapper{
    width: 100%;
}
.list-item{
    width: 33%;
    padding: 10px;
    box-sizing: border-box;
}
.list-item img{
    border-radius: 10px;
    width: 100%;
    height: 160px;
}