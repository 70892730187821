.calendar {
    width: 320px;
    height: 340px;
    border: 1px solid  #DFDFDF;
}
.calendar_title{
  width: 90%; 
  height: 43px;
  margin: 15px auto; 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  border: 1px solid #F5F6F9;
  border-radius: 25px;
  align-items: center;
  box-shadow: #F3F4F7 1px 1px 10px 1px;
}
.calendar_title .icon img{
  width: 15px; 
  height: 15px;
  margin: 5px 15px;
}
.calendar-body .week-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 20px;
}
.week{
    width: 14.2%;
    display: inline-block;
    text-align: center;
}
.day-container {
    height: 140px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.day {
    width: 14.2%;
    padding: 10px 0;
    display: inline-block;
    text-align: center;
}
.day.today{
    border-radius:21px;
    text-align:center;
    color:#fff;
    background-color:#43AF6A;
}
.disable{
    color: grey;
}
.date-marker{
    height:1px;
}
.circle-box{
    font-size: 25px;
}